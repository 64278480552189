<template>
    <div class="ShareEdit full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-form ref="form">
                <div class="content-area pa-5 flex-grow-1 bg-white">
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('system.share.type') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <v-select :items="selectShareType" item-text="name" item-value="value"
                                      v-model="shareEdit.type" class="shareEdit-type-dropdown" outlined
                                      hide-details="auto" attach :readonly="!checkUserRights('systemShareEdit')"
                                      :class="{'noAction': !checkUserRights('systemShareEdit')}"
                                      :rules="[v => !!v || $t('validation.required')]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('system.share.name') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <text-input inputName="shareEdit-name" @onInputFocus="onInputFocus"
                                        :readonly="!checkUserRights('systemShareEdit')"
                                        :class="{'noAction': !checkUserRights('systemShareEdit')}"
                                        :rules="[v => !!v || $t('validation.required'), rules.maxLengthName]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2">
                            <p>{{ $t('system.share.path') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <text-input inputName="shareEdit-path" @onInputFocus="onInputFocus"
                                        label="system.share.pathExpample"
                                        :readonly="!checkUserRights('systemShareEdit')"
                                        :class="{'noAction': !checkUserRights('systemShareEdit')}"
                                        :rules="[rules.maxLength48]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2" :class="{'disabled': shareEdit.type !== 'SMB'}">
                            <p>{{ $t('system.share.username') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <text-input inputName="shareEdit-username" @onInputFocus="onInputFocus"
                                        :disabled="shareEdit.type !== 'SMB'"
                                        :readonly="!checkUserRights('systemShareEdit')"
                                        :class="{'noAction': !checkUserRights('systemShareEdit')}"
                                        :rules="[rules.alphanumericDotValue, rules.maxLength24]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2" :class="{'disabled': shareEdit.type !== 'SMB'}">
                            <p>{{ $t('system.share.password') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <text-input inputName="shareEdit-password" @onInputFocus="onInputFocus" type="password"
                                        :disabled="shareEdit.type !== 'SMB'"
                                        :readonly="!checkUserRights('systemShareEdit')"
                                        :class="{'noAction': !checkUserRights('systemShareEdit')}"
                                        :rules="[rules.maxLength24]"/>
                        </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                        <v-col class="col-2" :class="{'disabled': shareEdit.type !== 'SMB'}">
                            <p>{{ $t('system.share.domain') }}</p>
                        </v-col>
                        <v-col class="col-10">
                            <text-input inputName="shareEdit-domain" @onInputFocus="onInputFocus"
                                        :disabled="shareEdit.type !== 'SMB'"
                                        :readonly="!checkUserRights('systemShareEdit')"
                                        :class="{'noAction': !checkUserRights('systemShareEdit')}"
                                        :rules="[rules.maxLength48]"/>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button v-if="shareEdit.connectionStatus === false && shareEdit.id != null" button-function="mount" :successCheck="showMountSuccess" :progress="showMountProgress"
                           :class="{'disabled': !checkUserRights('systemShareEdit')}"
                           @footerButtonClick="mountShare"></footer-button>
            <footer-button v-if="shareEdit.connectionStatus === true" button-function="unmount" :successCheck="showUnmountSuccess" :progress="showUnmountProgress"
                           :class="{'disabled': !checkUserRights('systemShareEdit') || shareEdit.id == null}"
                           @footerButtonClick="unmountShare"></footer-button>
            <footer-button buttonFunction="delete"
                           :class="{'disabled': !checkUserRights('systemShareDelete') || shareEdit.id == null}"
                           @footerButtonClick="deleteShare"></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="goToShare"></footer-button>
            <footer-button buttonFunction="save" :successCheck="showSaveSuccess" :progress="showSaveProgress"
                           :class="{'disabled': saveDisabled || !checkUserRights('systemShareEdit')}"
                           @footerButtonClick="save"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import TextInput from "@/components/TextInput.vue";
import mixins from "@/mixins/mixins";

export default {
    name: 'Share',
    components: {
        TextInput,
        FooterButton,
    },
    props: {},
    data() {
        return {
            saveDisabled: true,
            showSaveProgress: false,
            showSaveSuccess: false,
            showMountProgress: false,
            showMountSuccess: false,
            showUnmountProgress: false,
            showUnmountSuccess: false,
            original: {},
            selectShareType: [
                {
                    'name': this.$t('system.share.usb'),
                    'value': 'USB'
                }, {
                    'name': this.$t('system.share.network'),
                    'value': 'NFS'
                }, {
                    'name': this.$t('system.share.smb'),
                    'value': 'SMB'
                }
            ],
            rules: {
                maxLengthName: v => {
                    if (v) {
                        return v.length < 24 || `${this.$t('validation.chooseShorterName')}`;
                    } else return true;
                },
                maxLength24: v => {
                    if (v) {
                        return v.length < 24 || `${this.$t('validation.chooseShorterValue')}`;
                    } else return true;
                },
                maxLength48: v => {
                    if (v) {
                        return v.length < 48 || `${this.$t('validation.chooseShorterValue')}`;
                    } else return true;
                },
                alphanumericDotValue: v => {
                    if (v) {
                        let alphanumeric = /^[ A-Za-z0-9.]+$/;
                        return alphanumeric.test(v) || `${this.$t('validation.pleaseEnterValidValue')} ${this.$t('validation.noSpecialChar')}`;
                    } else return true;
                },
            }
        }
    },
    computed: {
        ...mapState([
            'shareEdit',
        ]),

    },
    methods: {
        goToShare() {
            this.$router.push('share');
        },
        deleteShare() {
            this.$store.dispatch('postAxiosNoSetter', ['/admin/share/delete', {'pathId': parseInt(this.shareEdit.id)}])
                .then(response => {
                    if (response.status === 200) {
                        this.goToShare();
                    }
                })
        },
        mountShare() {
            this.showMountProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/admin/share/mount', {'pathId': parseInt(this.shareEdit.id)}])
                .then(response => {
                    if (response.status === 200) {
                        this.showMountProgress = false;
                        this.showMountSuccess = true;
                        setTimeout(() => {
                            this.updateShareObject(this.shareEdit.id);
                        }, 800)
                        setTimeout(() => {
                            this.showMountSuccess = false;
                        }, 1600)
                    }else {
                        setTimeout(() => {
                            this.showMountProgress = false;
                            this.showMountSuccess = false;
                        }, 1600)
                    }
                })
        },
        unmountShare() {
            this.showUnmountProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/admin/share/unmount', {'pathId': parseInt(this.shareEdit.id)}])
                .then(response => {
                    if (response.status === 200) {
                        this.showUnmountProgress = false;
                        this.showUnmountSuccess = true;
                        setTimeout(() => {
                            this.updateShareObject(this.shareEdit.id);
                        }, 800)
                        setTimeout(() => {
                            this.showUnmountSuccess = false;
                        }, 1600)
                    } else {
                        setTimeout(() => {
                            this.showUnmountProgress = false;
                            this.showUnmountSuccess = false;
                        }, 1600)
                    }
                })
        },
        updateShareObject: function (id) {
            this.$store.dispatch('getAxiosSetter', ['/admin/share/pathlist', 'pathlist'])
                .then(response => {
                    if (response.status === 200) {
                        for (let index = 0; index < response.data.length; index++) {
                            const data = response.data[index];
                            if (data.id === id) {
                                this.shareEdit.connectionStatus = data.connectionStatus;
                                return;
                            }
                        }
                        // nothing found :(
                    }
                })
        },
        save() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.showSaveProgress = true;
                if (this.shareEdit.id != null) {
                    let Share = {}
                    if (this.shareEdit.type != this.original.type) {
                        Share.type = this.shareEdit.type;
                    }
                    if (this.shareEdit.name != this.original.name && this.shareEdit.name != '') {
                        Share.name = this.shareEdit.name;
                    }
                    if (this.shareEdit.path != this.original.path) {
                        Share.path = this.shareEdit.path;
                    }
                    if (this.shareEdit.username != this.original.username && this.shareEdit.username != '') {
                        Share.username = this.shareEdit.username;
                    }
                    if (this.shareEdit.password != this.original.password) {
                        Share.password = this.shareEdit.password;
                    }
                    if (this.shareEdit.domain != this.original.domain && this.shareEdit.domain != '') {
                        Share.domain = this.shareEdit.domain;
                    }
                    if (Object.keys(Share).length === 0 && Share.constructor === Object) {
                        this.showSaveProgress = false;
                        this.showSaveSuccess = true;
                        this.watcher();
                        setTimeout(() => {
                            this.saveDisabled = true;
                            this.showSaveSuccess = false;
                        }, 800)
                    } else {
                        Share.id = parseInt(this.shareEdit.id);
                        this.$store.dispatch('postAxiosNoSetter', ['/admin/share/edit', Share])
                            .then(response => {
                                this.showSaveProgress = false;
                                if (response.status === 200) {
                                    this.showSaveSuccess = true;
                                    this.watcher();
                                    setTimeout(() => {
                                        this.saveDisabled = true;
                                        this.showSaveSuccess = false;
                                    }, 800)
                                }
                            })
                    }
                } else {
                    let Share = {
                        //must send
                        "type": this.shareEdit.type,
                        "name": this.shareEdit.name,
                        "username": this.shareEdit.username,
                        "password": this.shareEdit.password,
                        "domain": this.shareEdit.domain,
                    }
                    if (this.shareEdit.path) {
                        Share.path = this.shareEdit.path;
                    }
                    this.$store.dispatch('postAxiosNoSetter', ['/admin/share/new', Share])
                        .then(response => {
                            this.showSaveProgress = false;
                            if (response.status === 200) {
                                this.$store.commit('SetShareEditToMounted');
                                this.shareEdit.id = response.data.id;
                                this.showSaveSuccess = true;
                                this.watcher();
                                setTimeout(() => {
                                    this.saveDisabled = true;
                                    this.showSaveSuccess = false;
                                }, 800)
                            }
                        })
                }
            }
        },
        watcher() {
            // copy ShareEdit to original for comparison
            this.original = JSON.parse(JSON.stringify(this.shareEdit));

            let type = this.$watch('$store.state.shareEdit.type', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let name = this.$watch('$store.state.shareEdit.name', function () {
                this.saveDisabled = false;
                stopWatch();
            });
            let path = this.$watch('$store.state.shareEdit.path', function () {
                this.saveDisabled = false;
                stopWatch();
            });
            let username = this.$watch('$store.state.shareEdit.username', function () {
                this.saveDisabled = false;
                stopWatch();
            });
            let password = this.$watch('$store.state.shareEdit.password', function () {
                this.saveDisabled = false;
                stopWatch();
            });
            let domain = this.$watch('$store.state.shareEdit.domain', function () {
                this.saveDisabled = false;
                stopWatch();
            });
            let status = this.$watch('$store.state.shareEdit.connectionStatus', function () {
                stopWatch();
            });
            const stopWatch = function () {
                type();
                name();
                path();
                username();
                password();
                domain();
                status();
            }
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#' + input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    created() {
        this.$emit("resetInput");
        this.watcher();
    },
    beforeRouteLeave(to, from, next) {
        if (this.saveDisabled) {
            next()
        } else {
            this.$confirm({
                message: this.$t('alerts.unsavedChanges'),
                show: true,
                cancelText: this.$t('general.cancel'),
                continueText: this.$t('general.continue')
            })
                .then(() => {
                    // continue
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>
<style scoped lang="scss">

</style>